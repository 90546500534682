
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUIBasicUsage from "@/views/resources/documentation/element-ui/form/radio/BasicUsage.vue";
import EUIDisabled from "@/views/resources/documentation/element-ui/form/radio/Disabled.vue";
import EUIRadioButtonGroup from "@/views/resources/documentation/element-ui/form/radio/RadioButtonGroup.vue";
import EUIButtonStyle from "@/views/resources/documentation/element-ui/form/radio/ButtonStyle.vue";
import EUIWithBorders from "@/views/resources/documentation/element-ui/form/radio/WithBorders.vue";

export default defineComponent({
  name: "radio",
  components: {
    EUIBasicUsage,
    EUIDisabled,
    EUIRadioButtonGroup,
    EUIButtonStyle,
    EUIWithBorders
  },
  setup() {
    setCurrentPageTitle("Radio");
  }
});
