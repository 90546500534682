
import { defineComponent, ref } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { code1 } from "./data";

export default defineComponent({
  name: "basic-usage",
  components: {
    CodeHighlighter
  },
  setup() {
    const radio = ref("1");
    return {
      radio,
      code1
    };
  }
});
